import React from 'react';

import config from '../config';
import Select from './Select';

const { birthYear } = config.grid;

const birthYearOptions = [];

for(let i=0; i < 100 ; i++){
  let year = {label: 2000 - i, value: i +1};
  birthYearOptions[i] = year;
}

const BirthYear = () => (
  <>
    <Select css={birthYear} name="birthYear" label="Année de naissance" options={birthYearOptions} required/>
  </>
);

export default BirthYear;
