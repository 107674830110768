import React from 'react';

import config from '../config';
import Select from './Select';

const {
  profession
} = config.grid;

const professionOptions = [{
  label: 'Salarié(e)',
  value: 21
}, {
  label: 'Artisan(e)',
  value: 8
}, {
  label: 'Commerçant(e)',
  value: 6
}, {
  label: 'Profession libérale',
  value: 22
}, {
  label: "Chef d'entreprise",
  value: 9
}, {
  label: 'Retraité(e)',
  value: 18
}, {
  label: 'Autre',
  value: 20
}]


const Profession = () => ( <>
  <
  Select css = {
    profession
  }
  name = "profession"
  label = "Votre profession"
  options = {
    professionOptions
  }
  required / >
  </>
);

export default Profession;