import styled from 'styled-components';

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.1rem;
  margin: 0 0 0.5rem;
`;

export const TitleForIe = styled.h4`
  font-weight: 700;
  font-size: 1.1rem;
  margin: -1.5rem 0 0.5rem;
`;

