import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadTagCo } from 'allianz-library';

import './css';
import App from './App';

window.tc_vars = {
  page_name: 'form',
};
loadTagCo('125');

ReactDOM.render(<App />, document.getElementById('root'));
