import { useContext } from 'react';
import { css } from 'styled-components';

import AppContext from '../../App/App.context';
import config from '../../config';

const styles = {
  large: {
    confirmationContainerStyle: css`
      padding: 4rem 8rem 2rem;
      -ms-grid-column-span: 6;
      -ms-grid-column: 1;
      grid-column-start: 3;
    `,
    wizardStyle: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: auto;
      -ms-grid-columns: ${config.grid.gridTemplateColumns};
      grid-template-columns: ${config.grid.gridTemplateColumns};
      margin: 4rem 2rem 0.5rem;
    `,
  },
  small: {
    confirmationContainerStyle: css`
      margin: 4rem 2rem 0.5rem;
    `,
    wizardStyle: css`
      margin: 4rem 2rem 0.5rem;
    `,
  },
};

function useStyle() {
  const { currentMediaConfig } = useContext(AppContext);

  return currentMediaConfig === 'large' ? styles.large : styles.small;
}

export default useStyle;
