import React from 'react';

import config from '../config';
import Select from './Select';
import { besoinOptions } from '../providers/besoin';

const {
  besoin
} = config.grid;

const Besoin = () => ( <>
  <
  Select css = {
    besoin
  }
  name = "besoin"
  label = "Votre besoin"
  options = {
    besoinOptions
  }
  required / >
  </>
);

export default Besoin;