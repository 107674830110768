import { useContext } from 'react';
import styled from 'styled-components';

import AppContext from '../../App/App.context';
import { RoundCheck } from '../../assets';
import { ButtonSubmit } from '../FormButtons/styledComponents';

const large = {
  ConfirmationContainer: styled.div`
    text-align: center;
    grid-column-start: 3;
    -ms-grid-column: 3;
  `,

  IconCheck: styled(RoundCheck)`
    vertical-align: top;
      height: 2.5rem;
      margin: 0.5rem 3rem;
      width: 2.5rem;
  `,

  TableContainer: styled.div`
    width: 700px;
    padding: 2rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #f0fafb;

    h3 {
      font-size: 2rem;
    }

    table {
      border-collapse: collapse;
      margin-top: 2rem;
      margin-left: auto;
      margin-right: auto;
    }

    td {
      border: 0.1rem solid;
      border-collapse: collapse;
      padding: 1.2rem 2rem;
      background-color: white;
    }

    svg {
      height: 1.5rem;
      margin-left: 5rem;
      width: 1.5rem;
    }

    path {
      color: #007ab3;
    }
  `,

  CustomTh: styled.th`
    border: 0.1rem solid;
    padding: 2rem 3rem;
    border-collapse: collapse;
    background-color: white;
  `,

  CustomTd: styled.td`
    text-align: left;
  `,

  TableTitle: styled.p`
    font-size: 2rem;
  `,

  TableDescription: styled.p`
    font-size: 0.7rem;
  `,

  PtagOnlyIe: styled.p`
  margin: 1rem auto 0 auto;
  width: 40rem;
  `,

  PtagExceptIe: styled.p`
  width: 40rem;
  margin: 1rem auto 0 auto;
  display: inline-block;
  `,
};

const small = {
  ConfirmationContainer: styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
  `,

  IconCheck: styled(RoundCheck)`
    vertical-align: top;
    height: 1.5rem;
    width: auto;
    margin: 1rem 0;
  `,

  TableContainer: styled.div`
    width: 230px;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #f0fafb;

    table {
      border-collapse: collapse;
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    td {
      border: 0.1rem solid;
      border-collapse: collapse;
      padding: 0.5rem 1rem;
      background-color: white;
    }
  `,

  CustomTh: styled.th`
    border: 0.1rem solid;
    padding: 0.5rem 1rem;
    border-collapse: collapse;
    background-color: white;
  `,

  CustomTd: styled.td`
    text-align: left;
  `,

  TableTitle: styled.p`
    font-size: 1.5rem;
  `,

  TableDescription: styled.p`
    font-size: 1rem;
  `,

  PtagOnlyIe: styled.p`
    margin: 1rem auto 0 auto;
  `,

  PtagExceptIe: styled.p`
  width: auto;
  max-width: 33rem;
  margin: 1rem auto 0 auto;
  display: inline-block;
  `,
};

function useStyledComponents() {
  const { currentMediaConfig } = useContext(AppContext);

  return currentMediaConfig === 'large' ? large : small;
}

export default useStyledComponents;

export const CustomButton = styled(ButtonSubmit)`
  display: block;
  margin: 3rem auto;
`;

export const CustomFooter = styled.footer`
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;

  p {
    font-size: 12px;
    margin-bottom: 1rem;
  }
`;



