import getUrlParameter from '../utils/getUrlParameter';

const besoinOptions = [
    {
        label: 'Constituer une épargne',
        value: 5
    },
    {
        label: 'Transmettre votre patrimoine',
        value: 1
    },
    {
        label: 'Percevoir un complément de revenu',
        value: 2
    },
    {
        label: 'Optimiser votre fiscalité',
        value: 6
    },
    {
        label: "Anticiper la perte d’autonomie",
        value: 7
    },
    {
        label: 'Assurer ma protection et celle de mes proches',
        value: 8
    },
    {
        label: 'Autre besoin',
        value: 9
    }
];

const besoinInitialValues = besoinOptions.filter(besoin => besoin.value === parseInt(getUrlParameter('besoin'))).pop()

const defaultValue = (() => {
    if (besoinInitialValues) {
        if (besoinInitialValues.label === 'Autre besoin') {
            return 'Besoin complémentaire';
        }
        return besoinInitialValues.label;
    }
    return '';
})();

export {
    besoinOptions,
    besoinInitialValues,
    defaultValue
};