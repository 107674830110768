import React from 'react';
import NameInputs from '../NameInputs';
import LocationInputs from '../LocationInputs';
import { Title, TitleForIe } from './styledComponents';
import BirthYear from '../BirthYear';
import RenderInBrowser from 'react-render-in-browser';
import Profession from '../Profession';
import Besoin from '../Besoin';

const Information = () => (
  <>
    <RenderInBrowser ie only>
      <TitleForIe>Vos informations</TitleForIe>
    </RenderInBrowser>
    <RenderInBrowser ie except>
      <Title>Vos informations</Title>
    </RenderInBrowser>
    <Besoin />
    <Profession />
    <NameInputs />
    <LocationInputs />
    <BirthYear />
  </>
);

export default Information;
