import React from 'react';
import PropTypes from 'prop-types';
import RenderInBrowser from 'react-render-in-browser';

import useStyledComponents from './useStyledComponents';

function Confirmation({ className }) {
  const {
    ConfirmationContainer,
    IconCheck,
    PtagExceptIe,
    PtagOnlyIe
  } = useStyledComponents();

  return (
    <ConfirmationContainer className={className}>
      <RenderInBrowser ie except>
        <PtagExceptIe>
          Nous avons bien enregistré les informations que vous nous avez transmises
        </PtagExceptIe>
      </RenderInBrowser>

      <RenderInBrowser ie only>
        <PtagOnlyIe>
          Nous avons bien enregistré les informations que vous nous avez transmises
        </PtagOnlyIe>
      </RenderInBrowser>

      <IconCheck />

      <RenderInBrowser ie except>
        <PtagExceptIe>
          Un conseiller Allianz, proche de chez vous, vous recontactera prochainement pour vous proposer une étude personnalisée.
        </PtagExceptIe>
      </RenderInBrowser>

      <RenderInBrowser ie only>
        <PtagOnlyIe>
          Un conseiller Allianz, proche de chez vous, vous recontactera prochainement pour vous proposer une étude personnalisée.
        </PtagOnlyIe>
      </RenderInBrowser>
    </ConfirmationContainer>
  );
}

Confirmation.defaultProps = {
  className: '',
};

Confirmation.propTypes = {
  className: PropTypes.string,
};

export default Confirmation;
