import { loadTagCo } from 'allianz-library';

async function sendFormValues(values) {
  const response = await fetch('/api?case=form', {
    method: 'POST',
    body: JSON.stringify({ ...values, ...window.contextTagCo }),
  });

  const contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    const result = await response.json();

    window.tc_vars = {
      page_name: 'confirmation',
      conversion_file_id: result.id_lead,
    };
    loadTagCo('125');

    return result;
  }

  throw new Error('Une erreur est survenue');
}

export default sendFormValues;
